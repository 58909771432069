import React, { Component } from 'react';
import { compose } from 'recompose';
import { withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import API from "../../util/Api.js"
import scrollToComponent from 'react-scroll-to-component';
import {Link} from "react-router-dom";
import MapInput from '../MapInput';
import Cart from '../Cart';
import Cookies from 'universal-cookie';
import ImgDeli from './icons8-delivery-48.png'
import Footer from '../Footer';
import Header from '../Header';
import {Helmet} from "react-helmet";
import Logo from '../img/logoRis.jpeg';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Prenota from '../Prenota';

import './fix.css';

var idP = process.env.REACT_APP_RESTORANT_TOKEN;//'5eb921d1035992dcafeaa666';



function decimal(num) {
  var n = num.toFixed(2);
  return n
}

function distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 === lat2) && (lon1 === lon2)) {
		return 0;
	}
	else {
		var radlat1 = Math.PI * lat1/180;
		var radlat2 = Math.PI * lat2/180;
		var theta = lon1-lon2;
		var radtheta = Math.PI * theta/180;
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;
		if (unit==="K") { dist = dist * 1.609344 }
		if (unit==="N") { dist = dist * 0.8684 }
		return dist;
	}
}

class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
				cart:[],
        cartMobile:false,
				carts:null,
        auth:null,
        item:null,
        lontano:false,
        categorie:[],
        struttura:[],
        visible : false,
				delyveryForm:false,
        selected:null,
        type:null,
        indirizzoDelivery:null,
        indirizzoDeliverySelect:null,
				reset:false,
        positionBar:false,
        yPosition:0,
    };


  }

  openModalAsk(){
      this.setState({
          visibleAsk : true
      });
  }
  openModal(info) {
        this.setState({
            visible : true,
            selected: info
        });
    }

    closeModal() {
        this.setState({
            visible : false,
            selected:null
        });
    }

    closeModalAsk(){
        this.setState({
            visibleAsk : false
        });
    }

  createCardSchema(items){
    let {categorie} = this.state;
    let struttura = [];
    categorie.forEach((categoriaA, i) => {
        let categoria = {
          id:categoriaA._id,
          nome:categoriaA.nome,
          piatti:[],
        }
        items.forEach((piatto, i) => {
          let index = piatto.categorie.indexOf(categoriaA._id)
          if(index >-1){
            categoria.piatti.push(piatto);
          }
        });
        if(categoria.piatti.length > 0){
          struttura.push(categoria)
        }

    });
    this.setState({struttura})

  }


	GetcartS(id){
		const ApiTags= new API ({url:'/cart'});
    var entity= {name:"cart"};
    ApiTags.createEntity(entity);
    var ppp = {};
    ApiTags.endpoints.cart
    .getOne({id:id},ppp)
    .then(res=>{

			if(res.data.status === 'ordine' || !res.data._id ){
				//alert('hai ancora un ordine in corso')
				this.CreatecartS()
			}else {
				  this.setState({carts:res.data});
			}


    });
  }

  CreatecartS(){
    console.log('ceo t');
		const cookies = new Cookies();
		const ApiTags= new API ({url:'/cart'});
    var entity= {name:"cart"};
    ApiTags.createEntity(entity);
    var ppp = {};

		var obj = {}
		var oneYearFromNow = new Date();
		oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    ApiTags.endpoints.cart
    .create(obj,ppp)
    .then(res=>{
      console.log('mostro stato');
      console.log(this.state);
      this.setState({carts:res.data});
			cookies.set('roo_cagui', res.data._id, { path: '/' , expires:oneYearFromNow});
    });
  }


  getRist(){
    //usa per il get
    const ApiTags= new API ({url:'/ristorante'});
    var entity= {name:"ristorante"};
    ApiTags.createEntity(entity);
    var ppp = {};
    ApiTags.endpoints.ristorante
    .getOne({id:idP},ppp)
    .then(res=>{
      this.setState({item:res.data});

      this.getCatgorie(res.data.menuPrincipale);
    });
  }

  getCatgorie(menuId){
    //usa per il get
    const ApiTags= new API ({url:'/categoriabymenu'});
    var entity= {name:"categoriabymenu"};
    ApiTags.createEntity(entity);
    var ppp = {};
    ApiTags.endpoints.categoriabymenu
    .getOne({id:menuId},ppp)
    .then(res=>{
      console.log('categor');
      console.log(res.data);
      this.setState({categorie:res.data})
      //this.getPiatti()
    });
  }




  isBottom(el) {
    if(el.getBoundingClientRect().top <= 0){
      return true;
    }
    return false
  }

  trackScrolling = (e) => {
  console.log(window.scrollY);
  const wrappedElement = document.getElementById('nav');

  if(window.scrollY >= this.state.yPosition){
    //console.log('fix');
    this.setState({positionBar:true})
  }else {
    //console.log('no fix');
    this.setState({positionBar:false})
  }

  };

  componentWillMount () {
        this.id = `toggle_${Math.random().toString().replace(/0\./, '')}`;
    }

  componentWillUnmount() {
    window.scrollTo(0,0);
    window.removeEventListener('scroll', this.trackScrolling)
  }
  componentDidMount() {
    window.scrollTo(0,0);
    const wrappedElement = document.getElementById('nav');
    this.setState({yPosition:wrappedElement.offsetTop})

    window.addEventListener('scroll', this.trackScrolling)
		const cookies = new Cookies();
		if(cookies.get('roo_cagui')){
			this.GetcartS(cookies.get('roo_cagui'))
		}else {
			this.CreatecartS()

		}

    this.getRist()
    let newThis = this;
    this.props.firebase.onAuthUserSpecial(function(user) {


        if(user){
          let auth = user;
          //auth.tok = id;

          newThis.setState({auth:auth})
        }
    })


  }

  openPopType(){
    let {carts} = this.state;

    if(carts && carts.type){
      switch (carts.type) {
        case 'take_away':
          return true
        default:
          if(carts.indirizzo){
            return true
          }
      }
    }else {
      return false
    }

  }

  getScontoBanner(item){
    let {carts} = this.state;
    if(carts && carts.type){
      switch (carts.type) {
        case 'take_away':
          if(item.scontoAsp && item.scontoAsp > 0){
            return <div className='tagSconto'>{item.scontoAsp}%</div>
          }else {
            return null
          }

        default:
        if(item.sconto && item.sconto > 0){
          return <div className='tagSconto'>{item.sconto}%</div>
        }else {
          return null
        }
      }
    }else {
      return null
    }
  }

  createAndOpen(piatto){
    this.saveDeliveryInfo('take_away');
    this.openModal(piatto)
  }

  cardPiatto(piatti){
    return piatti.map((piatto,i)=>{
      return(
        <div onClick={!this.openPopType() ? ()=>this.createAndOpen(piatto) : ()=>this.openModal(piatto)} key={i} style={{display:'flex'}} className='cardList'>
          <div style={{padding: 20}}>
            <h4 style={{fontSize:16,fontWeight:400,marginTop:0,marginBottom:9,textTransform:'capitalize'}}>{piatto.nome}</h4>
          <div className='f1'>
            <div style={{fontSize:14,color:'#828585'}} >{piatto.desc}</div>
          </div>
					<div style={{marginTop:10}} className='f1'>
            <div style={{fontSize:16,color:'#828585'}} > {decimal(piatto.prezzo)} €</div>
          </div>
          </div>
          <div style={{ marginLeft: 'auto',position:'relative'}}>
            {this.getScontoBanner(piatto)}
            {piatto.img ? <img style={{width:100,objectFit: 'cover',height:'100%'}} alt={piatto.nome} src={piatto.img}/> : null}
          </div>
        </div>
      )
    })
  }

  calculateDist(geoMy){
    let {item} = this.state;
    let c1 = item.areaLavoro.coordinates

    let c2 = geoMy.geometry.coordinates

    let distancePosy = distance(c1[0],c1[1],c2[0],c2[1],'N');
    if(distancePosy > 1){
      this.setState({lontano:true})
    }else {
      this.setState({lontano:false,indirizzoDelivery:geoMy})
    }


  }


popDelivery(obj){
  let {delyveryForm,indirizzoDelivery,lontano} = this.state;

	if(delyveryForm){
		return(
			<div className='mappaInput'>
				<MapInput callback={(x)=>this.calculateDist(x)} />
				{lontano ? <div><p style={{marginTop:30,fontSize:30,textAlign:'center'}}>Ci dispiace, per ora non consegnamo fino qui.</p><p>Se vuoi procedi con il <div style={{marginTop:20}} onClick={()=>this.saveDeliveryInfo('take_away')} className='buttonAsk'>Take away</div></p></div> : null}
				{!lontano && indirizzoDelivery ?
					(<div>
						<p style={{padding:10,fontSize:23}}>{indirizzoDelivery.place_name}</p>
						<button onClick={()=>this.saveDeliveryInfo(null,indirizzoDelivery)} style={{marginLeft:'auto',marginRight:'auto',marginTop:50}} className='buttonAsk' >Seleziona</button>
					</div>
					)
				 : null}
			</div>
		)
	}

}

addPiatto(selected,composto){
	let {carts} = this.state;
  console.log('qui faccio x vedere le aggiunte o altro');
  console.log(selected.options);
  console.log('mostrooooooo');
  console.log(selected);
  let text = ''
  selected.options.forEach((opzione, i) => {
    if(opzione.chois == 'Selezione'){
      text = text+' '+opzione.nome+': '+selected[opzione.nome].nome;
    }else {
      text = text+' '+opzione.nome+':';
      selected[opzione.nome].forEach((item, i) => {
        text =  text+' '+item.nome+' '
      });


    }

  });
  console.log('vedamo testo');
  console.log(text);



	const ApiTags= new API ({url:'/cartadditem'});
	var entity= {name:"cartadditem"};
	ApiTags.createEntity(entity);
  console.log('cart id');
  console.log(carts._id);
	var ppp = {};
	let obj = {
		"nome" : selected.nome,
		"prezzo" :selected.prezzo,
		"qty":selected.qty ? selected.qty : 1,
		"id":carts._id,
    'idOb':selected._id,
	 }


  if(composto){
    obj.total= selected.total / (selected.qty ? selected.qty : 1);
    obj.customPart= text;
    obj.custom= true
  }else {
    obj.total= selected.total;
  }

	ApiTags.endpoints.cartadditem
	.update(obj,ppp)
	.then(res=>{

		//carts.items.push(obj)

		this.setState({carts:res.data,visible:false})
	});

}

changeQty(type){

	let {selected} = this.state;
  if(selected.componibile){
    if(selected.total){
      if(type){
        console.log('qui 1');
        let quota = selected.total / selected.qty ;
        if(selected.qty){
          selected.qty = selected.qty > 1 ? selected.qty - 1 : 1;
          selected.total = quota * (selected.qty ? selected.qty : 1)
        }else {
          selected.qty = 1
          selected.total = selected.total
        }

        console.log(selected.total);
        console.log(selected.qty);
        console.log(quota);



    	}else {
        console.log('qui 2 +');
        console.log(selected.total);
        console.log(selected.qty);
        let quota = selected.total / (selected.qty ? selected.qty : 1) ;
        console.log(quota);
    		selected.qty = (selected.qty ? selected.qty : 1) + 1;
    		selected.total = quota * (selected.qty ? selected.qty : 1); //qui
    	}
    }else {
      if(!selected.qty){
        console.log('qui 3 boh');
    		selected.qty = 1;
    		selected.total = selected.prezzo;
    	}
    	if(type){
        console.log('qui 4 -');
    		selected.qty = selected.qty - 1;
    		selected.total = selected.prezzo * selected.qty;

    	}else {
        console.log('qui 5 +');
    		selected.qty = selected.qty + 1;
    		selected.total = selected.prezzo * selected.qty;
    	}
    }
  }else {
    if(!selected.qty){
  		selected.qty = 1;
  		selected.total = selected.prezzo;
  	}
  	if(type){
  		selected.qty = selected.qty - 1;
  		selected.total = selected.prezzo * selected.qty;

  	}else {
  		selected.qty = selected.qty + 1;
  		selected.total = selected.prezzo * selected.qty;
  	}
  }


	this.setState({selected})
}



saveDeliveryInfoDelivery(type){

	const cookies = new Cookies();
	let obj = {
		id:cookies.get('roo_cagui')
	}
	obj.type = type

	const ApiTags= new API ({url:'/cart'});
	var entity= {name:"cart"};
	ApiTags.createEntity(entity);
	var ppp = {};

	ApiTags.endpoints.cart
	.update(obj,ppp)
	.then(res=>{

		//carts.items.push(obj)

		this.setState({carts:res.data,delyveryForm:true})

	});
}

saveDeliveryInfo(type,indirizzo){

  console.log('delevery set');
	const cookies = new Cookies();
	let obj = {
		id:cookies.get('roo_cagui')
	}
	if(type){
		obj.type = type
	}if(indirizzo){

		obj.indirizzo = indirizzo.geometry;
		obj.indirizzoCompleto = indirizzo.place_name;
		obj.IndirizzoVia=indirizzo.text;
	  obj.address=indirizzo.address;
	  obj.cap=indirizzo.context[0].text;
	  obj.citta=indirizzo.context[1].text;
    //obj.type = 'take_away'

	}

	const ApiTags= new API ({url:'/cart'});
	var entity= {name:"cart"};
	ApiTags.createEntity(entity);
	var ppp = {};

	ApiTags.endpoints.cart
	.update(obj,ppp)
	.then(res=>{

		//carts.items.push(obj)
		if(type === 'take_away'){
			this.setState({carts:res.data,visibleAsk:false},()=>{
        console.log('satato nuovo');
        console.log(this.state);
        console.log('stato');
        console.log(this.state.carts.type);
        console.log('pasato');
        console.log(type);
      })
		}else {
			if(indirizzo){
				this.setState({carts:res.data,visibleAsk:false,delyveryForm:false})
			}
		}

	});
}

subTotal(){
	let {carts} = this.state;
	let subTotal = 0;
	if(carts){
		carts.items.forEach((x,i)=>{

			if(x.qty && x.qty > 0){
      	subTotal = subTotal + (x.qty * x.prezzo);
      }
		})
	}

	return decimal(subTotal)
}

azzera(){
	this.setState({reset:true},()=>{
		this.openModalAsk()
	})


}

take_awayAction(){
	let {item} = this.state;
	if(item && item.take_away){
		return(
			<div><div onClick={()=>this.saveDeliveryInfo('take_away')} className='buttonAsk'>Take away</div>
			<p>Evita di fare code! noi prepariamo e tu passi a prendere al ristorante</p></div>
		)
	}else {
		return null
	}

}

deliveryAction(){
	let {item} = this.state;
	if(item && item.delivery){
		return(
			<div><div onClick={()=>this.saveDeliveryInfoDelivery('delivery')} className='buttonAsk'>Delivery</div>
			<p>Direttamente a casa tua</p></div>
		)
	}else {
		return null
	}

}

preorderAction(){
	let {item} = this.state;
	if(item && item.preordine){
		return(
			<div><div onClick={()=>this.saveDeliveryInfoDelivery('preorder')} className='buttonAsk'>Preordine</div>
			<p>Tu ordina entro ... e noi ti portiamo  </p></div>
		)
	}else {
		return null
	}

}

pezziTotali(){
  let {carts} = this.state;
  let totale = 0;
  if(carts){
    carts.items.forEach((x,i)=>{
      if(x.qty && x.qty > 0){
        totale = totale + x.qty;
      }

    })
  }

  return totale
}


serviziOnline(){
	return(
		<div style={{padding:20}}>
				<h2 style={{fontSize:30}}>Quale modalita di ordine vuoi utilizare oggi ?</h2>
				<div>
					{this.take_awayAction()}
					{this.deliveryAction()}
					{this.preorderAction()}
				</div>
		</div>
	)
}


decideType(){
  let {carts,item} = this.state;
  if(carts && item && item.delivery && item.take_away){
    return (<div style={{display:'flex'}}>
      <div style={{marginLeft:0,width:'100%'}}>
        <p style={{margin:0, fontWeight:400,fontSize:16,lineHeight:'22px'}}>{carts.type === 'take_away' ? '*Hai scelto di ritirare qui da noi' : 'Hai scelto la consegna a casa'}</p>
        {carts.type !== 'take_away' && carts.indirizzo ? <p style={{fontSize:14,color:'#828585',marginTop:0}}>{carts.indirizzoCompleto}</p> : null}
      </div>
      <div onClick={()=>this.azzera()} style={{marginLeft:'auto',cursor:'pointer',textAlign:'center',color:'var(--color-primary)'}}>modifica</div>

    </div>)
  }else {
    return null
  }

}

addValueRadio(value,name){
  //console.log(value);
  let {selected} = this.state;
  let qty = selected.qty ? selected.qty : 1;
  if(selected[name]){
    let dif =  (value.price - selected[name].price )* qty;
    console.log('differ');
    console.log(dif);
    selected.total = selected.total + dif;

  }else {
    if(selected.total){
      selected.total = selected.total + (value.price * qty)
    }else {
      selected.total = selected.prezzo + (value.price * qty)
    }

  }
  selected[name] = value;

  this.setState({selected})
  console.log(selected);
}

addValueCkb(value,name,x){
  console.log('polllo');
  let {selected} = this.state;
  let qty = selected.qty ? selected.qty : 1;
  console.log(selected);
console.log(value);
  if(x.target.checked){
    if(selected[name]){
      selected[name].push(value);
    }else {
      selected[name] = [];
      selected[name].push(value);
    }

    if(selected.total){
      selected.total = selected.total + (value.price * qty)
    }else {
      selected.total = selected.prezzo + (value.price * qty)
    }
  }else {
    let index = selected[name].findIndex((x)=> x._id == value._id)
    console.log(index);
    selected[name].splice(index, 1);
    selected.total = selected.total - (value.price * qty)
  }

  this.setState({selected})
  console.log(selected);
}

optionBox(){
  let {selected} = this.state;
  return selected.options.map((option,i)=>{
    switch (option.chois) {
      case 'Selezione':
      return (<div style={{padding:10}}>
                <strong style={{textTransform:'capitalize',marginBottom:30}}>{option.nome}</strong>
                <div style={{marginTop:10}}>
                  {option.option.map((item,x)=>{
                    return (
                    <div style={{display:'flex',padding:18,border:'1px solid #eee',marginBottom:5,borderRadius: 34}}>
                      <div><input name={option.nome} type='radio'  id={item._id} onChange={()=>this.addValueRadio(item,option.nome)} value={item.nome}/></div>
                      <div><label  htmlFor={item._id} style={{marginLeft:10,cursor:'pointer'}}>{item.nome}</label></div>
                      <div style={{marginLeft:'auto'}}><label style={{marginLeft:10}}>{item.price > 0 ? '('+decimal(item.price)+')€' : null}</label></div>
                    </div>)
                  })}
                </div>
              </div>
              )
      case 'Extra':
      return (<div style={{padding:10}}>
                <strong style={{textTransform:'capitalize',marginBottom:30}}>{option.nome} {option.maxSelect}</strong>
                <div style={{marginTop:10}}>
                  {option.option.map((item,xi)=>{
                    return (
                    <form key={xi}>
                    <div htmlFor={item._id} style={{display:'flex',padding:18,border:'1px solid #eee',marginBottom:5}}>
                      <div><input name={option.nome} type='checkbox' id={item._id} value={item.nome} onChange={(x)=>this.addValueCkb(item,option.nome,x,xi)}/></div>                      <div style={{width:'100%'}} ><label  htmlFor={item._id} style={{marginLeft:10,display: 'block',cursor:'pointer'}}>{item.nome}</label></div>
                      <div style={{marginLeft:'auto'}}><label  htmlFor={item._id} style={{marginLeft:10}}>{item.price > 0 ? '('+decimal(item.price)+')€' : null}</label></div>
                    </div>
                  </form>
                  )
                  })}
                </div>
              </div>
              )
      default:
      return (<div style={{padding:10}}>
                <strong style={{textTransform:'capitalize',marginBottom:30}}>{option.nome} {option.chois}</strong>
                <div style={{marginTop:10}}>
                  {option.option.map((item,x)=>{
                    return (
                    <div style={{display:'flex',padding:10}}>
                      <div><input name={option.nome} type='checkbox' value={item.nome} onChange={()=>console.log(item)}/></div>
                      <div><label style={{marginLeft:10}}>{item.nome}</label></div>
                      <div style={{marginLeft:'auto'}}><label style={{marginLeft:10}}>{item.price > 0 ? '('+decimal(item.price)+')€' : null}</label></div>
                    </div>)
                  })}
                </div>
              </div>
              )

    }
  })
}


render() {
    let {yPosition,positionBar,delyveryForm,carts,loading, auth,item,categorie,struttura,selected} = this.state;
    return (
      <div>
				<Helmet>
            <meta charSet="utf-8" />
            <title>{item ? item.nome : null}</title>
        </Helmet>
        <Modal open={this.state.visibleAsk}  effect="fadeInUp" onClose={() => this.closeModalAsk()}>
            {!carts || !carts.type || !delyveryForm ? (
              this.serviziOnline()
            ) : this.popDelivery(carts)

           }
        </Modal>
        <Modal open={this.state.cartMobile}effect="fadeInUp" onClose={() => this.setState({cartMobile:false})}>
            {loading ? <p>...</p> : <div>
              <Cart action={(carts) => this.setState({carts:carts})} close={(carts) => this.setState({cartMobile:false})}  auth={auth} speseSped={item ?item.prezzoConsegna : null} cart={this.state.carts} />
              {this.decideType()}
            </div>}
        </Modal>
        <Modal open={this.state.visible}  effect="fadeInUp" onClose={() => this.closeModal()}>
            <div className='modalType' >
                {selected ? (
                  <div>
                    <div>
                      <h2 style={{marginTop:30,textAlign:'center',textTransform: 'capitalize'}}>{selected.nome}</h2>
                    </div>
                    {selected.img ? (
											<img style={{width:'100%',maxHeight:300,objectFit: 'cover'}} src={selected.img} alt={selected.nome} />
										) : null}
                    <p style={{marginTop:10}}>{selected.desc}</p>

                      {selected.componibile ? (
                        this.optionBox()
                      ) : null}

											<div style={{display: 'table',marginLeft:'auto',marginRight:'auto',textAlign:'center'}}>
			                  <div style={{display:'flex',fontSize:30,marginTop:20}}>
			                    <div onClick={()=>this.changeQty(true)} style={{marginRight:30,fontWeight:900,cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path className='colorp' d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z"/></svg></div>
			                    <div style={{fontSize:30,fontWeight:800}}>{selected.qty ? selected.qty : 1 }</div>
			                    <div onClick={()=>this.changeQty()} style={{marginLeft:30,fontWeight:900,cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20px" height="20px"><path className='colorp' d="M7.9,256C7.9,119,119,7.9,256,7.9C393,7.9,504.1,119,504.1,256c0,137-111.1,248.1-248.1,248.1C119,504.1,7.9,393,7.9,256z"/><path fill="#FFF" d="M391.5,214.5H297v-93.9c0-4-3.2-7.2-7.2-7.2h-68.1c-4,0-7.2,3.2-7.2,7.2v93.9h-93.9c-4,0-7.2,3.2-7.2,7.2v69.2c0,4,3.2,7.2,7.2,7.2h93.9v93.4c0,4,3.2,7.2,7.2,7.2h68.1c4,0,7.2-3.2,7.2-7.2v-93.4h94.5c4,0,7.2-3.2,7.2-7.2v-69.2C398.7,217.7,395.4,214.5,391.5,214.5z"/></svg></div>
			                  </div>
			                </div>
                      <div>

                      </div>
                </div>
                ) : null}
                <div className='buttonModalBar' >
                  <div ><button onClick={() => this.closeModal()} className='buttonAction' >Cancella</button></div>
                  {selected && selected.componibile? (
                      <div style={{width:'100%'}}><button className='buttonAction' onClick={()=>this.addPiatto(selected,true)} >Aggiungi {selected ? decimal(selected.total ? selected.total : selected.prezzo) : 0}€</button></div>
                  ) : (
                      <div style={{width:'100%'}}><button className='buttonAction' onClick={()=>this.addPiatto(selected)} >Aggiungi {selected ? decimal(selected.total ? selected.total : selected.prezzo) : 0}€</button></div>
                  )}
                </div>
            </div>
        </Modal>

        <Header actionButton={()=>this.azzera()} home={true} auth={auth} item={item}  carts={carts} />
        {/*<div className='barra'>
          <div className='specialBar'></div>
          <div style={{background:'transparent',position:'relative',zIndex:1,display:'flex'}} className='containerFix'>
            <Link to="/"><div>
              <img style={{width:100,padding:10,borderRadius: 66}} src={Logo} alt='logo_ristorante'/></div></Link>
            <div style={{display:'flex',marginLeft:'auto',marginTop: 8}}>
              <div className='buttonActionBar ' style={{display: 'inline-table',fontWeight:300}}><svg xmlns="http://www.w3.org/2000/svg" className='colorp' width="16" height="16" viewBox="0 0 24 24"><path d="M4.558 7l4.701-4.702c.199-.198.46-.298.721-.298.613 0 1.02.505 1.02 1.029 0 .25-.092.504-.299.711l-3.26 3.26h-2.883zm12.001 0h2.883l-4.702-4.702c-.198-.198-.459-.298-.72-.298-.613 0-1.02.505-1.02 1.029 0 .25.092.504.299.711l3.26 3.26zm7.441 2v2h-.642c-.534 0-1.022.305-1.257.786l-4.101 10.214h-12l-4.101-10.216c-.234-.48-.722-.784-1.256-.784h-.643v-2h24zm-15 4c0-.552-.448-1-1-1s-1 .448-1 1v5c0 .552.448 1 1 1s1-.448 1-1v-5zm4 0c0-.552-.448-1-1-1s-1 .448-1 1v5c0 .552.448 1 1 1s1-.448 1-1v-5zm4 0c0-.552-.447-1-1-1s-1 .448-1 1v5c0 .552.447 1 1 1s1-.448 1-1v-5z"/></svg> € {this.subTotal()}</div>
              {auth ? (
								<Link to="/menu"><div className='buttonActionBar' style={{display: 'inline-table',fontWeight:300}}>Menu</div></Link>

              ) : (
                <div className='buttonActionBar' style={{display: 'inline-table',fontWeight:300}}><Link to="/signin">Log in</Link></div>
              )}

            </div>
          </div>
          <div className='container restaurant' >
            <div style={item && item.img ? {backgroundImage:'url('+item.img+')',backgroundSize: 'cover',backgroundPosition: 'center'} : {} } className='containerFix grid02'>

              <div style={{padding:10}}>

              </div>
            </div>
          </div>
          <div className='containerFix' style={{background:'transparent',padding:10,marginTop:10}}>

            <h2 style={{marginTop:0,color:'#828585'}}>{item ? item.nome : null }</h2>
            <p style={{marginTop:10,color:'#828585',fontSize:16}}>{item ? item.desc : null }</p>
              <div className='boxService' style={{display:'flex'}}>
                {item && item.take_away ? <div onClick={()=>this.azzera()} style={{background:carts && carts.type == 'take_away' ? '#d6d6d6' : null, opacity: carts && carts.type == 'take_away' ? 1 : 0.7}}>
                  <p style={{color:'#828585',fontSize:20}}><img style={{height:40}} src="https://img.icons8.com/cotton/100/000000/take-away-food.png"/></p>
                  <p>Asporto</p>
                </div> : null}
                {item && item.delivery ? <div onClick={()=>this.azzera()} style={{background:carts && carts.type == 'delivery' ? '#d6d6d6' : null ,opacity: carts && carts.type == 'delivery' ? 1 : 0.7}}>
                  <p style={{color:'#828585',fontSize:20}}><img style={{height:40}} src="https://img.icons8.com/cotton/64/000000/delivery-scooter.png"/></p>
                  <p>Delivery</p>
                </div> : null}

              </div>
        </div>




          <div></div>
        </div>*/}

        <div style={positionBar  ? {position:'fixed',top:0,zIndex:3,left:0,right:0} : {position:'relative'}} id='nav' className='containerFix barraCategorie'>
            <nav ref='navbarprova'>
              {categorie.map((x,i)=>{
                return <div onClick={() => scrollToComponent(this[''+x.nome.split(' ').join('_')], { offset: -80, align: 'top', duration: 1000})} className='categoriaDiv'><button  style={{border:'none',background:'none',cursor:'pointer',background:'var(--color-primary)',color:'white',padding:10,borderRadius:10,fontWeight:600}}>{x.nome}</button></div>
              })}

            </nav>

            <div className='cassa'>
              <Prenota cassa={true} history={this.props.history} auth={auth} data={item ? item : null} />

  						{loading ? <p>...</p> : <Cart auth={auth} speseSped={item ?item.prezzoConsegna : null} cart={this.state.carts} />}

              {this.decideType()}
            </div>
        </div>
        <div className='gridPiatti'>
          <div className='piatti'>
            {
              categorie.map((categoria,i)=>{
                return(
                  <div className={(categoria.nome.split(' ').join('_'))+' lista' } ref={(section) => { this[''+categoria.nome.split(' ').join('_')] = section; }}>
                    <h2 style={{fontWeight: 400, fontSize: 28,textTransform: 'capitalize',padding: '48px 12px 16px 0'}}>{categoria.nome}</h2>
                    <div  className='boxlist'>
                      {this.cardPiatto(categoria.piatti)}
                    </div>
                  </div>
                )
              })
            }


          </div>

          <div  className='cassaMobile' >
            <div onClick={()=>this.setState({cartMobile:true})} style={{display:'flex'}}><div className='conterC' style={{position: 'absolute',background:'white',display:'table',marginTop:10}}>{this.pezziTotali()}</div>
            <button style={{fontSize:16,fontWeight:600,color:'white',textAlign:'center',width:'100%',marginTop:37,background: 'transparent',border: 'none'}}>vedi il carrello</button>
              <div className='conterC' style={{color:'white',background:'transparent', display:'table',marginTop:10,position:'absolute',right:10}}>€{this.subTotal()}</div>
              </div>
              <div className='' style={{background:'#eee',padding:10,marginTop:20}}>
                {/*this.decideType()*/}
                  <Prenota cassa={true} history={this.props.history} auth={auth} data={item ? item : null} />

            </div>
        </div>
        </div>
          <p style={{fontWeight:300,padding:10}}>*In caso di allergie alimentari o esigenze dietetiche particolari, ti preghiamo di avvisarci direttamente.</p>
				<div className='footr'>
					<Footer />
				</div>
			</div>



    )
  }
}

//const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification
)(HomePage);
