import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import asyncComponent from "../AsyncComponent";


import HomePagePages from '../Home';
import checkOutPage from '../CheckOut';
import menuPage from '../Menu';
import Privacy from '../Privacy';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import * as ROUTES from '../../constants/routes';
import ReactGA from 'react-ga';


import { withAuthentication } from '../Session';
/*
const HomePagePages = asyncComponent(() => import("../Home"));
const checkOutPage = asyncComponent(() => import("../CheckOut"));
const menuPage = asyncComponent(() => import("../Menu"));
const SignUpPage = asyncComponent(() => import("../SignUp"));
const SignInPage = asyncComponent(() => import("../SignIn"));*/
const trackingId = "UA-167442651-2";
ReactGA.initialize(trackingId);


const App = () => (
  <Router>
    <div>
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route exact path={ROUTES.LANDING} component={HomePagePages} />
      <Route exact path={ROUTES.CHECKOUT} component={checkOutPage} />
      <Route exact path={ROUTES.MENU} component={menuPage} />
      <Route exact path={ROUTES.PRIVACY} component={Privacy} />
    </div>
  </Router>
);

export default withAuthentication(App);
